import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Tooltip,
    IconButton, TextareaAutosize
} from "@material-ui/core";
import {
    GetAddButton,

} from "../../Utilities/Utility";
//import { Link } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Link from '@material-ui/core/Link';
import DownloadIcon from "@material-ui/icons/ArrowDownwardOutlined";
//import EditPrepareOffer from "../Screens/Offer/EditPrepOffer";
import PrepareOffer from "../Offer/PrepareOffer";
import MaterialTable from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { BootstrapInput } from "../../Utilities/Utility";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'; import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EmailIcon from '@material-ui/icons/Email';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { DeleteOutlined } from "@material-ui/icons";
//import "./POEntryPage.scss";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GetAllPoEntryDetails, copyPOEntryDetails, deletePOEntryAttachments, getCommercialStatusMaster, getCustomerDetailsMaster, getProductMaster, getSerialNoYearMaster, getTechnicalStatusMaster, poEntryAttachmentsBasedonId, postPOEntryDetails, updatePOEntryDetails, getNewMake, getOldMake, getReason, getCountry, GetSearch, getTypeId, deletePOEntry, GetObject, getOffer, getReference, getOfferTable, updateGrid, DelGrid, getSign, updateApproval, getCommercial, downloadACBReports, SendOfferEmailToCustomer } from "../../APIs/api_PoEntry";
import { getOrder } from "../../APIs/api_Insert";
import { getCountryMaster, getState, getEngineerMaster, getOfficeMaster, getRegionMaster } from "../../APIs/api_Customer";
import { uploadOffer } from "../../APIs/api_Insert"
import { refNameAction } from "../../Redux/Actions/actions";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Loader from "../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import {
    getService
} from "../../APIs/api_Insert";
import { downloadOffer } from "../../APIs/api_Quotation"
import { offer, Type, year } from "../../Redux/Actions/actions";
import { geAllTypeReports } from "../../APIs/api_Insert"
import {
    getRoundUptoXDecimals,
    tableCellCurrentFY,
    tableCellPreviousFY,
    tableIcons,
    renderValueWithThousandSeparator,
    tdInputCurrentFY,
    tdTotalInPut,
} from "../../Utilities/Utility";
import {
    MTableBody,
    MTableGroupRow,
    MTableHeader,
    MTablePagination,
} from "material-table";
import {

    makeStyles,
    TableBody,
    ThemeProvider,
} from "@material-ui/core";
import { baseApiURL } from "../../Utilities/Utility";
import parse from "html-react-parser";
import * as FileSaver from "file-saver";
//import EditPrepareOffer from "./EditPrepOffer";
const tableHeaderStyle = {
    backgroundColor: "var(--light-sand)",
    color: "var(--dark-blue)",
};

export default function Offer(props) {
    const useStyles = makeStyles(() => ({
        paperSty: {
            padding: "40px",
            marginTop: "30px",
        },
        childGrid: {
            marginTop: "20px",
        },
        btnGrid: {
            justifyContent: "center",
            marginTop: "2rem",
        },
        tableCellPreviousFY: tableCellPreviousFY,
        tableCellCurrentFY: tableCellCurrentFY,
        tableCellTotal: tdTotalInPut,
    }));
    const classes = useStyles();
    //const navigate = useNavigate();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [yearFilter, setYearFilter] = useState("");
    const yearFilterChange = (e) => {
        console.log("year", e.target.value);
        setYearFilter(e.target.value);
        dispatch(year(e.target.value));
    }



    const d1 = new Date();
    const [prodFilter, setProdFilter] = useState(0);
    const [ref, setRef] = useState("");
    const [data, setData] = useState([]);
    const prodFilterChange = (e) => {
        setProdFilter(e.target.value);
        /*getRef(e.target.value)*/
        dispatch(offer(e.target.value))
        dispatch(Type(e.target.name))
    }
    //offer grid
    const [refe, setRefe] = useState("");
    const [cust, setCust] = useState("");
    const [stat, setStat] = useState(null);
    const [status, setStatus] = useState(null);
    const [amt, setAmt] = useState(null);
    const [offerID, setOfferID] = useState(null)
    const [prepID, setPrepID] = useState(null)
    //console.log("offerID", offerID);
    //new handle

    // openEmailDialog
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [to, setTo] = useState("");
    const [cc, setCC] = useState("");
    const [toHelper, setToHelper] = useState("");
    const [ccHelper, setCCHelper] = useState("");
    const [subject, setSubject] = useState("");
    const [refnumber, setRefNumber] = useState("");
    const [body, setBody] = useState("");
    const [subjectHelper, setSubjectHelper] = useState("");
    const [bodyHelper, setBodyHelper] = useState("");
    let valid;

    function handleClickOpenEmailDialog(rowData) {

        dispatch(refNameAction(rowData.offerReferenceNumberName));
        setRefNumber(rowData.offerReferenceNumberName);
        setOpenEmailDialog(true);
        setTo(rowData.mailTo);
        setCC(rowData.mailCc);
        setSubject(rowData.mailSubject);
        setBody(rowData.mailBody);
    };

    const handleCloseEmailDialog = () => {
        setOpenEmailDialog(false);
    };
    const handleChangeTo = (event) => {
        setTo(event.target.value);
        valid = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})(,\s*[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/.test(event.target.value);
        if (!valid) {
            setToHelper("Invalid Email");
        } else {
            setToHelper("");
        }
    };
    const handleChangeCC = (event) => {
        setCC(event.target.value);
        valid = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})(,\s*[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/.test(event.target.value);
        if (!valid) {
            setCCHelper("Invalid Email");
        } else {
            setCCHelper("");
        }
    };
    const handleChangeSubject = (event) => {
        setSubject(event.target.value);
        if (event.target.value.length === 0) {
            setSubjectHelper("Cannot be left blank.");
        }
        else {
            setSubjectHelper("")
        }
    };
    const handleChangeBody = (event) => {
        setBody(event.target.value);
        if (event.target.value.length === 0) {
            setBodyHelper("Cannot be left blank.");
        }
        else {
            setBodyHelper("")
        }
    };


    //Email DIalog Ends

    const handleChangeRef = (event) => {
        setRef(event.target.value);



    };
    const handleChangeCust = (event) => {
        setCust(event.target.value);



    };
    const handleChangeStatus = (event) => {
        setStatus(event.target.value);



    };
    const handleChangeStat = (event) => {
        setStat(event.target.value);



    };
    const handleChangeAmt = (event) => {
        setAmt(event.target.value);



    };


    function handleUpdate() {
        setIsLoading(true);
        var formData = {
            PrepareOrderId: prepID,
            OfferId: offerID,
            OfferReferenceNumberId: 0,
            OfferReferenceNumberName: refe,
            CustomerId: customer,
            CustomerName: null,
            EstimateDate: est,
            ValidityDate: selectedDate,
            StatusId: status,
            StateId: stat,
            Amount: amt,
            CreatedDate: selectedDate,
            IsActive: true,
            ModifiedBy: userDetails.userId,
            CreatedBy: userDetails.userId,


        }
        updateGrid(formData, siteToken)
            .then((response) => {
                console.log("ResponseRef", response)
                //setRef(response);
                handleCloseForm();
                handleSnackOpen(
                    response.responseMsg,

                    "success"
                );

                getOffTable()
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                //handleSnackOpen(error, "error");
                handleSnackOpen("Error in hitting Api", "error");

            })


    }
    function Del() {
        setIsLoading(true);
        var data = {
            PrepareOrderId: deleteRowData.prepareOrderId,
            ModifiedBy: userDetails.userId,
            YearId: yearFilter,
        }
        DelGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, data)
            .then((response) => {
                console.log("del", response)
                //setRef(response);
                handleCloseDeleteDialog()
                handleSnackOpen(
                    response.responseMsg,

                    "success"
                );

                getOffTable()
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                //handleSnackOpen(error, "error");
                handleSnackOpen("Error in hitting Api", "error");

            })


    }

    function getRef(offerId) {
        setIsLoading(true);
        getReference(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, offerId)
            .then((response) => {
                console.log("ResponseRef", response)
                setRef(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })


    }
    function getOffTable() {

        //if (!prodFilter) {
        //    handleSnackOpen("Please select Product Type ", "error")
        //}

        setIsLoader(true);
        getOfferTable(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, prodFilter, yearFilter)
            .then((response) => {
                console.log("table", response)
                setData(response.objResult);
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen(error, "error");
            })


    }



    const [pending, setPending] = useState("Unassigned")
    const dispatch = useDispatch();
    const col = [
        {

            title: "Revision",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.isRevised === true ? <VisibilityIcon /> : "",

        },
        { title: "Validity Date", field: "validityDate", type: "date" },
        { title: "Estimate Date", field: "estimateDate", type: "date" },
        { title: "Reference", field: "offerReferenceNumberName" },
        { title: "Customer Name", field: "customerName" },
        { title: "Status", field: "status" },
        { title: "State", field: "state" },
        //{
        //    title:"link",
        //    field: "email",
        //    headerName: "Email",
        //    width: 300,
        //    renderCell: (params) => (
        //        <Link href={params}>Link</Link>
        //    )
        //},
        //{
        //    title: "Link",
        //    field: "link",
        //    editable: false,
        //    render: (rowData) => (
        //        <Button>
        //            <DownloadIcon
        //                className="checkIcon"
        //                onClick={() => Assign(rowData)}
        //            />
        //        </Button>
        //    ),
        //},
        {
            title: "Technical Approval Pending with",
            field: "approvalPendingWith",

            editable: (rowData) => { return (rowData.approvalPendingWith != "Technical Approval Completed") },
            render: (rowData) => (
                //setPending(rowData.approvalPendingWith),
                <Link


                    component="button"
                    variant="body2"
                    onClick={() => {
                        if (rowData.approvalPendingWith != "Technical Approval Completed" || (rowData.approvalPendingWith == "Technical Approval Completed" && userDetails.roleId == 1)) {

                            Assign(rowData)
                        }
                    }}
                >
                    {rowData.approvalPendingWith}
                </Link>
            ),
        },
        {
            title: "Commercial Approval Pending With",
            field: "commercialApprovalPendingWith",

            editable: (rowData) => { return (rowData.commercialApprovalPendingWith != "Commercial Approval Completed") },
            render: (rowData) => (
                //setPending(rowData.approvalPendingWith),
                <Link


                    component="button"
                    variant="body2"
                    onClick={() => {
                        if (rowData.commercialApprovalPendingWith != "Commercial Approval Completed" || (rowData.commercialApprovalPendingWith == "Commercial Approval Completed" && userDetails.roleId == 1)) {
                            Assign(rowData)
                        }

                    }}
                >
                    {rowData.commercialApprovalPendingWith}
                </Link>
            ),
        },
        {
            title: "Preview",
            field: "product",
            editable: false,
            render: (rowData) => (

                <Button>

                    <DownloadIcon
                        className="checkIcon"
                        onClick={() => { download(rowData) }}
                    />
                </Button>
            ),
        },
        {
            title: "Upload Offer", field: "product", editable: false,
            render: rowData => rowData.enableUploadOfferIcon ? <Button>
                <CloudUploadIcon
                    className="checkIcon"
                    onClick={() => handleOpenDia(rowData)

                    }
                />
            </Button> : <></>
        },
        {
            title: "Send Email", field: "product", editable: false,
            render: rowData => rowData.enableMailIcon ? <Button>
                <EmailIcon
                    className="checkIcon"
                    //onClick={() => Sendmail(rowData)
                    onClick={() => handleClickOpenEmailDialog(rowData)

                    }
                />
            </Button> : <></>
        },
        //{ title: "Amount", field: "amount" },
        //{ title: "Discount(%)", field: "discountPercentage" },
        //{ title:"Discount(Amount)", field: "discount" },
        //{ title: "Cgst", field: "cgst" },
        //{ title: "Sgst", field: "sgst" },
        //{ title: "Igst", field: "igst" },
        //{ title: "Adujustment", field: "adjustment" },
        //{ title: "OtherCharges", field: "otherCharges" },
        //{ title: "Total", field: "total" },

    ]

    function Sendmail(rowData) {
        setIsLoading(true);
        var formData = {
            MailTo: to,
            MailCC: cc,
            MailSubject: subject,
            MailBody: body,
            OfferReferenceNumber: refnumber,
        }

        SendOfferEmailToCustomer(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("mailsent", response)
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    handleCloseEmailDialog();
                }
                else {
                    handleSnackOpen(response.responseMsg, "error");
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })


    }
    //End - snackbar
    const [openDia, setOpenDia] = useState(false);
    const [row, setRow] = useState("");
    const handleOpenDia = (rowData) => {
        //clear();
        setOpenDia(true);
        setRow(rowData);
    };
    const handleCloseDia = () => {
        //clear();
        setOpenDia(false);
    };
    const d =
        [
            {
                name: "8/16/2023",
                iname: "Sw0000908",
                date: "",
                status: "Asian PVT LMT",
                action: "Sent",
                pend: "900000"
            },
            {
                name: "10/16/2023",
                iname: "Aw0000908",
                date: "",
                status: "Anasol",
                action: "Sent",
                pend: "1000000"
            },


        ]
    const [totalValue, setTotalValue] = useState(null);
    const [totalValues, setTotalValues] = useState([]);
    const handleChangeTotalValue = (event) => {
        setTotalValue(event.target.value);



    };
    const [approve, setApprove] = useState(null);
    const [approves, setApproves] = useState([]);
    const handleChangeApprove = (event) => {
        setApprove(event.target.value);



    };
    const [prepId, setPrepId] = useState(null)
    const [techflag, setTechFlag] = useState(false)
    function Assign(rowData) {

        setOpenDropdown(true)

        if (rowData.approvalPendingWith === "Technical Approval Completed") {
            setTotalValue(null)
            setTechFlag(true)
        }
        // setTotalValue(rowData.total)
        // setTotalValue(rowData.approvalPendingWith)
        setPrepId(rowData.prepareOrderId)
        setIsLoader(true);
        getSign(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, rowData.total, rowData.approvalStatusL1, rowData.approvalStatusL2, rowData.approvalStatusL3)
            .then((response) => {
                console.log("sign", response)
                setTotalValues(response);
                //setApproves(response);
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen(error, "error");
            })
        getComm()




    }
    const [fileurl, setfileurl] = useState("");
    function download(rowData) {
        setIsLoader(true);
        //if (rowData.fileUrl == null) {
        //    setIsLoader(false);
        //    handleSnackOpen("Certificate Not Uploaded", "warning");
        //    return false;
        //}
        //console.log(rowData.ourRef);
        dispatch(refNameAction(rowData.offerReferenceNumberName));
        var ref = rowData.offerReferenceNumberName;
        // var customer = rowData.customerName;
        downloadOffer(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, ref)
            .then((response) => {
                console.log(response)

                //setfileurl(response.fileUrl)
                //files(rowData)
                setIsLoader(false);
                if (response == "No records found") {
                    setIsLoader(true);

                    geAllTypeReports(siteToken,
                        azureToken.token,
                        UID.id,
                        userDetails.userEmail,
                        userDetails.gid,
                        userDetails.roleName,
                        userDetails.roleId, ref)
                        .then((response) => {
                            console.log(response)

                            //setfileurl(response.fileUrl)
                            //files(rowData)
                            setIsLoader(false);

                            response.fileUrl != null || response.fileUrl != "" ? window.open(response.fileUrl, "_blank") : handleSnackOpen("Error while downloading Report.", "error");

                        })
                        .catch((error) => {
                            setIsLoader(false);
                            handleSnackOpen("Error while downloading Report.", "error");
                        })
                }
                else {
                    response.fileUrl != null || response.fileUrl != "" ? window.open(response.fileUrl, "_blank") : handleSnackOpen("Error while downloading Report.", "error")


                }

            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }

    function files(rowData) {
        fileurl != null ? <a target="_blank" href={fileurl}><span className="link-filed">Download Drawings</span></a>
            : dispatch(refNameAction(rowData.offerReferenceNumberName));
        window.open("/PreviewPage", "_blank")

    }
    function getComm() {
        setIsLoader(true);
        getCommercial(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("sign1", response)
                //setTotalValues(response);
                setApproves(response);
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen(error, "error");
            })

    }
    function clearDrop() {
        setApprove(null)
        setTotalValue(null)
    }
    const handleApprove = () => {
        //setFormDialogTitle("Save");

        let formData = {

            PrepareOrderId: prepId,
            ApprovalPendingWithL1: totalValue,
            CommercialApproval: approve,
            // CreatedBy: userDetails.userId,
            ModifiedBy: userDetails.userId,

        }

        updateApproval(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("approve", response)
                if (response.response) {

                    // handleCloseDia()
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    handleCloseDropdown()
                    clearDrop()
                    getOffTable()
                    //getItemGrid(refId)
                    //getUsers()

                } else {

                    // handleCloseDia()
                    clearDrop()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                // handleCloseDia()
                clearDrop()
                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }

    let [totals, setTotals] = useState([
        {
            nOofBreaker_ACB: 0,
            poValue_ACB: 0,
            poValue_EMS: 0,
            poValue_Panel: 0,
            poValue_Retrofit: 0,
            totalFronts_Panel: 0,
            breakerQty_Retrofit: 0,




        },
    ]);
    const [custObj, setCustObj] = useState([]);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function handleCloseDropdown() {
        setOpenDropdown(false);
        setApprove("")
        setTotalValue("")
    };
    const [obj, setObj] = useState([]);
    const [prodMasterData, setProdMasterData] = useState([]);
    function getProdMaster() {
        setIsLoading(true);
        getOffer(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("ResponseProd", response)
                setProdMasterData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    const [uniqueSerialMaster, setUniqueSerialMaster] = useState([]);
    function getSerialNoYear() {
        setIsLoading(true);
        getSerialNoYearMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setUniqueSerialMaster(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function country() {
        setIsLoading(true);
        console.log("dated1", d1)
        getCountryMaster(siteToken, userDetails.userId)
            .then((response) => {
                console.log("countryid", response);
                setCountryName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function state(countryid) {
        setIsLoading(true);
        getState(siteToken, userDetails.userId, countryid)
            .then((response) => {
                console.log("state", response);
                setStateName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getOffice(userId, EngineerId) {
        setIsLoading(true);
        getOfficeMaster(siteToken, userId, EngineerId)
            .then((response) => {
                console.log("office", response);
                setOfficeName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getRegion(userId, EngineerId) {
        setIsLoading(true);
        getRegionMaster(siteToken, userId, EngineerId)
            .then((response) => {
                console.log("region", response);
                setRegionName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function SetCustyear() {
        console.log("hi");
        setIsLoading(true);

        GetSearch(siteToken, userDetails.userId, prodFilter, yearFilter)
            .then((response) => {
                console.log("search", response);

                setAllPOEntry(response.list);
                setTotals([{ ...response.totals }]);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function grid(userId, prodFilter, yearFilter) {
        //console.log("hi");
        //setProdFilter(1);
        setIsLoader(true);

        console.log("prod", prodFilter);
        GetSearch(siteToken, userId, prodFilter, yearFilter)
            .then((response) => {
                console.log("search", response);

                setAllPOEntry(response.list);
                setTotals([{ ...response.totals }]);

                setIsLoader(false);

            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function object(PoEntryId) {
        GetObject(siteToken, userDetails.userId, PoEntryId)
            .then((response) => {
                console.log("objectresp", response);

                setObj(response);

            })
            .catch((error) => {

                handleSnackOpen(error, "error");
            })

    }
    function codeName() {
        setIsLoading(true);
        getCountry(siteToken, userDetails.userId)
            .then((response) => {
                console.log("country", response);

                setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }

    function reason() {
        setIsLoading(true);
        getReason(siteToken, userDetails.userId)
            .then((response) => {
                console.log("country", response);
                setRes(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }

    function typeId() {
        setIsLoading(true);
        getTypeId(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("typeid", response);
                setTypes(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function newMake() {
        setIsLoading(true);
        getNewMake(siteToken, userDetails.userId)
            .then((response) => {
                console.log("country", response);
                setNewBreak(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function oldMake() {
        setIsLoading(true);
        getOldMake(siteToken, userDetails.userId)
            .then((response) => {
                console.log("country", response);
                setOld(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }


    const [techStatusMaster, setTechStatusMaster] = useState([]);
    const [techStatus, setTechStatus] = useState("");
    const techStatusChange = (e) => {
        setTechStatus(e.target.value);
    }
    function getTechStatusMaster() {
        setIsLoading(true);
        getTechnicalStatusMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("Tech Status", response);
                setTechStatusMaster(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }

    const [commercialStatusMaster, setCommercialStatusMaster] = useState([]);
    const [commercialStatus, setCommercialStatus] = useState("");
    const commercialStatusChange = (e) => {
        setCommercialStatus(e.target.value);
    }
    function getCommStatusMaster() {
        setIsLoading(true);
        getCommercialStatusMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("Comm Status", response);
                setCommercialStatusMaster(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    const [order, setOrder] = useState([]);
    const [orderValue, setOrderValue] = useState("");
    const handleOrder = (event) => {
        setOrderValue(event.target.value);
        //setOrderId(event.target.value);


    };
    function Setorder() {
        console.log("hi");
        //setIsLoader(true);

        getOrder(siteToken, userDetails.userId)
            .then((response) => {
                console.log(response);

                setOrder(response);
                //setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                //setIsLoader(false);
            });
    }
    // AutoComplete
    const [customer, setCustomer] = useState("");
    const [customerObj, setCustomerObj] = useState("");
    const [customerText, setCustomerText] = useState("");
    const [itemsCustomer, setItemsCustomer] = useState([]);
    const [errorCustomer, setErrorCustomer] = useState(false);
    const [errorTextCustomer, setErrorTextCustomer] = useState("");

    const handleChangeCustomer = (event, newValue) => {
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
        console.log("Customer", newValue.epCustomerId);
    };
    const handleChangeCustomerInput = (event, newInputValue) => {
        setCustomerText(newInputValue);
        //setCodeText(newInputValue);
    };
    const [code, setCode] = useState("");
    const [codeObj, setCodeObj] = useState("");
    const [codeText, setCodeText] = useState("");
    const [itemsCode, setItemsCode] = useState([]);
    const [errorCode, setErrorCode] = useState(false);
    const [errorTextCode, setErrorTextCode] = useState("");

    const handleChangeCode = (event, newValue) => {
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        console.log("Code", newValue.epCustomerId);


    };
    const handleChangeCodeInput = (event, newInputValue) => {
        setCodeText(newInputValue);
        //setCustomerText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
            getCustDetailsMaster(newInputValue);
        }


    };
    function getCustDetailsMaster(value) {
        //setIsLoading(true);
        getCustomerDetailsMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, value)
            .then((response) => {
                setItemsCustomer(response);
                setItemsCode(response);
                //setOptions(response);
                console.log("cst code", response);
                // setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    //const [code, setCode] = useState([]);
    const [codeVal, setCodeVal] = useState(null);
    function codeValChange(e) {
        setCodeVal(e.target.value);
    }
    const [countryName, setCountryName] = useState([]);
    const [countryVal, setCountryVal] = useState("");

    const [types, setTypes] = useState([]);

    const [typeVal, setTypeVal] = useState("");

    const handleType = (event) => {
        setTypeVal(event.target.value);



    };
    function countryValChange(e) {
        setCountryVal(e.target.value);
        state(e.target.value);
    }
    //const count = countryName.filter((item) => item.id === countryVal);

    // setCodeVal(count[0].countryId);

    const [stateVal, setStateVal] = useState("");
    const [stateName, setStateName] = useState([]);
    function stateValChange(e) {
        setStateVal(e.target.value);
    }
    const [regionVal, setRegionVal] = useState("");
    const [regionName, setRegionName] = useState([]);
    function regionValChange(e) {
        setRegionVal(e.target.value);
    }
    const [officeVal, setOfficeVal] = useState("");
    const [officeName, setOfficeName] = useState([]);
    function officeValChange(e) {
        setOfficeVal(e.target.value);
    }
    const [service, setService] = useState([]);
    const [serviceTypeVal, setServiceTypeVal] = useState("");
    function serviceTypeChange(e) {
        setServiceTypeVal(e.target.value);
    }
    const [poNumberVal, setPONumberVal] = useState("");
    function poNumberValChange(e) {
        setPONumberVal(e.target.value);
    }
    const [poValueVal, setPOValueVal] = useState("");
    function poValueValChange(e) {
        setPOValueVal(e.target.value);
    }
    // const [poValidityVal, setPOValidityVal] = useState("");
    // function poValidityValChange(e) {
    //     setPOValidityVal(e.target.value);
    // }
    const [noOfBreakersVal, setNoOfBreakersVal] = useState("");
    function noOfBreakersValChange(e) {
        setNoOfBreakersVal(e.target.value);
    }
    const [ticketNumberVal, setTicketNumberVal] = useState("");
    function ticketNumberValChange(e) {
        setTicketNumberVal(e.target.value);
    }
    const [soNumberVal, setSONumberVal] = useState("");
    function soNumberValChange(e) {
        setSONumberVal(e.target.value);
    }
    const [assignedResourceVal, setAssignedResourceVal] = useState("");
    function assignedResourceValChange(e) {
        setAssignedResourceVal(e.target.value);
    }

    const [allPOEntry, setAllPOEntry] = useState([]);
    //retrofit onchnange
    const [breakVal, setBreakVal] = useState("");
    function breakValChange(e) {
        setBreakVal(e.target.value);
    }
    const [res, setRes] = useState([]);
    const [resVal, setResVal] = useState("");
    function resValChange(e) {
        setResVal(e.target.value);
    }
    const [old, setOld] = useState([]);
    const [oldVal, setOldVal] = useState("");
    function oldValChange(e) {
        setOldVal(e.target.value);
    }
    const [newBreak, setNewBreak] = useState([]);
    const [newVal, setNewVal] = useState("");
    function newValChange(e) {
        setNewVal(e.target.value);
    }
    const [notVal, setNotVal] = useState("");
    function notValChange(e) {
        setNotVal(e.target.value);
    }
    const [retroVal, setRetroVal] = useState("");
    function retroValChange(e) {
        setRetroVal(e.target.value);
    }
    //ems
    const [orderVal, setOrderVal] = useState("");
    function orderValChange(e) {
        setOrderVal(e.target.value);
    }
    const [meterVal, setMeterVal] = useState("");
    function meterValChange(e) {
        setMeterVal(e.target.value);
    }
    const [licenseVal, setLicenseVal] = useState("");
    function licenseValChange(e) {
        setLicenseVal(e.target.value);
    }
    const [digiVal, setDigiVal] = useState("");
    function digiValChange(e) {
        setDigiVal(e.target.value);
    }
    //panel
    const [totalVal, setTotalVal] = useState("");
    function totalValChange(e) {
        setTotalVal(e.target.value);
    }
    const [overallVal, setOverallVal] = useState("");
    function overallValChange(e) {
        setOverallVal(e.target.value);
    }
    function getAllPoEntryDetails() {
        setIsLoading(true);
        GetAllPoEntryDetails(siteToken, userDetails.userId)
            .then((response) => {
                console.log("material", response);
                setAllPOEntry(response);
                console.log("po", response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function Service() {
        console.log("hi");
        setIsLoading(true);

        getService(siteToken, userDetails.userId)
            .then((response) => {
                console.log("service", response);
                setService(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    const [rollHelper, setRollHelper] = useState("");
    const [poHelper, setPoHelper] = useState("");
    const handleChangeSoNo = e => {
        let valid;


        switch (e.target.id) {
            case "rollnumber":
                setSONumberVal(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setRollHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setRollHelper("");

                }
                break;
            case "po":
                setPOValueVal(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setPoHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setPoHelper("");

                }
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        setIsLoading(true);

        console.log("userDetails", userDetails)

        setYearFilter(44);
        dispatch(year(44));
        //// setProdFilter(1);
        // //if (grid.length==0) {
        // //    setIsLoading(true)
        // //    //
        // //}
        // //else { setIsLoading(false) }
        // //getAllPoEntryDetails();
        typeId();
        getProdMaster();
        getSerialNoYear();
        getTechStatusMaster();
        getCommStatusMaster();
        // getCustDetailsMaster();
        //country();
        //codeName();
        //reason();
        //newMake();
        //Setorder();
        //oldMake();
        //Service();
        //grid(userDetails.userId,1, 43);
        //getOffice(userDetails.userId,userDetails.userId);
        // getRegion(userDetails.userId, userDetails.userId);
        //console.log("date",inputFileValue)
        setProdFilter(0)
        console.log("prod", prodFilter)
        getOffTable()
    }, []);
    useEffect(() => {
        setIsLoading(true);
        console.log("userDetails", userDetails.userId)
        if (userDetails.userId > 0) {
            //grid(userDetails.userId, 1, 43);

            // getOffice(userDetails.userId, userDetails.userId);

            // getRegion(userDetails.userId, userDetails.userId);
            //console.log("date",inputFileValue)
        }
    }, [userDetails]);


    const [selectedFile, setSelectedFile] = useState(null);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    let [errorData, setErrorData] = useState([])
    const fileUploadHandler = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files);
        setSelectedFile(chosenFiles);
        console.log("SelectedFile", event.target.files);
    };
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    function submitValueDetails() {
        const formData = new FormData();
        setIsLoader(true);
        formData.append("file", selectedFile);
        formData.append("UserId", userDetails.userId);
        if (offerID === 10) {
            formData.append("Type", "Engineer Deputation");
        }
        else {
            formData.append("Type", "ACB Spares");
        }
        formData.append("AutomatedReferenceNumber", row.offerReferenceNumberName);
        //UploadData(formData);
        uploadOffer(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("hi");
                console.log(response);
                if (response.response) {
                    setIsLoader(false);
                    getOffTable();
                    handleSnackOpen(response.responseMsg);
                    handleCloseDia();
                } else {
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileValue(Date.now);
                    console.log(response.objResult);
                    setErrorData(response.objResult);
                    handleSnackOpen(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                    handleCloseDia();
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setSelectedFile(null);
                setInputFileValue(Date.now);
                handleSnackOpen("Exception in upload");
            });
    }

    var columns = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },
        {
            title: 'Service Type',
            field: 'serviceType'
        },
        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },

        {
            title: 'No. Of Breakers',
            field: 'noOfBreaker'
        },
        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },
        {
            title: 'SO Number',
            field: 'sonumber'
        },
        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },
        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];
    var columnsRetro = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },
        {
            title: 'Reason For Order Win',
            field: 'reasonForOrderWin'
        },
        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },

        {
            title: 'Breaker Qty',
            field: 'breakerQty'
        },
        {
            title: 'Old Make Breaker',
            field: 'oldmakeBreaker'
        },
        {
            title: 'New Breaker Model',
            field: 'newBreakerModel'
        },
        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },
        {
            title: 'Notification Number',
            field: 'notificationNumber'
        },
        {
            title: 'SO Number',
            field: 'sonumber'
        },

        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },
        {
            title: 'Retrofit Partner',
            field: 'retrofitPartner'
        },
        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];
    var columnsEms = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Type", field: "typeName" },
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },

        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },
        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'OrderType',
            field: 'orderTypeName'
        },
        {
            title: 'Meters Make',
            field: 'metersMake'
        },

        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },

        {
            title: 'SO Number',
            field: 'sonumber'
        },
        {
            title: 'License Quantity',
            field: 'licenseQuantity'
        },

        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },
        {
            title: 'Digi Partner',
            field: 'digiPartner'
        },
        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];
    var columnsPanel = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },

        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },

        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },

        {
            title: 'OrderType',
            field: 'orderTypeId'
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },
        {
            title: 'Total Fronts',
            field: 'totalFronts'
        },

        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },

        {
            title: 'SO Number',
            field: 'sonumber'
        },


        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },

        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        {
            title: 'Overall Qty Services',
            field: 'overallQtyServices'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];


    function renderFileDownload(rowData) {
        return (
            <Button
                href={rowData.fileUrlWithSasToken}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>);
    }
    function deleteAttachment(rowData) {
        deletePOEntryAttachments(siteToken, rowData)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                getPOAttachments(siteToken, rowData.poEntryId, userDetails.userId);
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    function deletePoEntryRow() {
        deletePOEntry(siteToken, deleteRowData.epPoentryPageId)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                handleCloseDeleteDialog();
                grid(userDetails.userId, deleteRowData.productId, deleteRowData.yearId);

            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    function renderFileDelete(rowData) {
        const rowD = rowData;
        return (
            <Button
                color="transparent"
                onClick={() => deleteAttachment(rowD)}
            >
                <Tooltip title="Delete Attachment">
                    <DeleteOutlineOutlinedIcon />
                </Tooltip>
            </Button>);
    }

    var attachColumns = [
        {
            title: "File Name",
            field: "filename"
        },
        {
            title: "Download",
            render: rowData => renderFileDownload(rowData)
        },
        {
            title: "Delete",
            render: rowData => renderFileDelete(rowData)
        },
    ];

    // Dialog Part
    const [openDialog, setOpenDialog] = useState(false);
    const [custDialogTitle, setCustDialogTitle] = useState("Add PO Entry");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [dialogSubmitBtn, setDialogSubmitBtn] = useState("Save");

    function clearFormValues() {
        setCustomerObj("");
        setCustomer("");
        setCustomerText("");
        setCodeVal("");
        setCountryVal("");
        setCodeObj("");
        setCode("");
        setCodeText("");
        setEst(null);
        setStateVal("");
        setRegionVal("");
        setOfficeVal("");
        setServiceTypeVal("");
        setPONumberVal("");
        setSelectedDate(null);
        setPOValidityDate(null);
        setPOValueVal("");
        setNoOfBreakersVal("");
        setTicketNumberVal("");
        setSONumberVal("");
        setAssignedResourceVal("");
        setTechStatus("");
        setCommercialStatus("");
        setSelectedFile(null);
        setInputFileValue(Date.now);
        setEPPoentryId(null);
        setFileAttachments([]);
        //retrofit
        setBreakVal("");
        setResVal("");
        setOldVal("");
        setNewVal("");
        setNotVal("");
        setRetroVal("");
        //ems
        setOrderVal("");
        setMeterVal("");
        setLicenseVal("");
        setDigiVal("");
        setTypeVal("");
        //panel
        setTotalVal("");
        setOverallVal("");
    }
    const handleCloseForm = () => {
        //clearFormValues();
        setOpenDialog(false);
    };
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteRowData(null);
    };
    const handleDeleteIconClick = (rowData) => {
        setDeleteRowData(rowData);
        setDeleteDialogOpen(true);
    };
    function handleCopyIconClick(rowData) {
        setIsLoading(true);
        copyPOEntryDetails(siteToken, rowData.yearId, rowData.epPoentryPageId, userDetails.userId).
            then((response) => {
                setIsLoading(false);
                handleSnackOpen(response.responseMsg, "success");
                getAllPoEntryDetails();
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error")
            })
    }
    //function GetAddButton() {
    //    return (
    //        <Tooltip title="Add" placement="bottom">
    //            <span>
    //                <Button href="javascript:void(0)">
    //                    <span>
    //                        <AddBoxOutlinedIcon fontSize="small" className="addFileIcon" />
    //                    </span>
    //                </Button>
    //            </span>
    //        </Tooltip>
    //    );
    //}
    function GetEditButton() {
        return (
            <Tooltip title="Edit" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetFileButton() {
        return (
            <Tooltip title="file" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <InsertDriveFileIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetCloseButton() {
        return (
            <Tooltip title="close" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <CloseIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetCopyButton() {
        return (
            <Tooltip title="Copy" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <FileCopyOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    const [epPoentryId, setEPPoentryId] = useState(null);
    const [fileAttachments, setFileAttachments] = useState([]);
    const [flag, setFlag] = useState(false);
    function getPOAttachments(siteToken, epPoentryPageId, userId) {
        setIsLoading(true);
        poEntryAttachmentsBasedonId(siteToken, epPoentryPageId, userId)
            .then((response) => {
                setFileAttachments(response);

                console.log("Attch Response", response);
                if (response.length > 0) {
                    setFlag(true)
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    const [disc, setDisc] = useState(null)
    const [discA, setDiscA] = useState(null)
    const [adj, setAdj] = useState(null)
    const [others, setOthers] = useState(null)
    const [tottal, setTottal] = useState(null)
    const [igst, setIgst] = useState(null)
    const [sgst, setSgst] = useState(null)
    const [cgst, setCgst] = useState(null)
    const dialogDraftValuesFn = (rowData) => {
        console.log("Row Data", rowData);
        setOfferID(rowData.offerId)
        setPrepID(rowData.prepareOrderId)
        setDialogSubmitBtn("Update");
        setOpenDialog(true);
        setRefe(rowData.offerReferenceNumberName)
        setCust(rowData.customerName)
        setCustomerObj(rowData.customerObject[0]);
        setCustomer(rowData.customerObject[0] ? rowData.customerObject[0].epCustomerId : 0);
        setCustomerText(rowData.customerName);
        setAmt(rowData.amount)
        setStat(rowData.stateId)
        setStatus(rowData.statusId)
        setSelectedDate(rowData.validityDate)
        setEst(rowData.estimateDate)
        setIgst(rowData.igst)
        setSgst(rowData.sgst)
        setCgst(rowData.cgst)
        setDisc(rowData.discountPercentage)
        setDiscA(rowData.discount)
        setAdj(rowData.adjustment)
        setTottal(rowData.total)
        setOthers(rowData.otherCharges)




    };
    const [editRowData, seteditRowData] = useState([]);
    // const [mode, setMode] = useState("");
    function clickEdit(rowData) {
        console.log("edit data");
        console.log(rowData);
        setMode("edit");
        setopenForm(true);
        seteditRowData(rowData);

        // window.location.href = "/EditPrepareOffer";


    }
    const goBackonClick = (obj) => {
        setopenForm(false);
        // setopenLoader(false);
        console.log("obj", obj);
        //viewDisplay()
        //if (obj) {
        //    GetViewSlipDetails();
        //    //let errorList = [];
        //    //if (obj.result.objResult) {
        //    //}
        //    //errorList.push(obj.result.responseMsg);
        //    //if (obj.result.response) {
        //    //    showMessage(errorList, true, 'success');
        //    //}
        //    //else {
        //    //    showMessage(errorList, true, 'error');
        //    //}
        //}
    }

    // const siteToken = useSelector((state) => state.saveTokenReducer.token);
    function handleSaveForm() {
        const formData = new FormData();
        if (customer === "" || customer === null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (code === "" || code === null || code === undefined) {
            handleSnackOpen("code is Empty", "error");
            return;
        }
        if (stateVal === "" || stateVal === null || stateVal === undefined) {
            handleSnackOpen("state is Empty", "error");
            return;
        }
        if (countryVal === "" || countryVal === null || countryVal === undefined) {
            handleSnackOpen("country is Empty", "error");
            return;
        }
        if (regionVal === "" || regionVal === null || regionVal === undefined) {
            handleSnackOpen("region is Empty", "error");
            return;
        }
        if (officeVal === "" || officeVal === null || officeVal === undefined) {
            handleSnackOpen("office is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (serviceTypeVal === "" || serviceTypeVal === null || serviceTypeVal === undefined) {
                handleSnackOpen("serviceType is Empty", "error");
                return;
            }
        }
        if (poNumberVal === "" || poNumberVal === null || poNumberVal === undefined) {
            handleSnackOpen("poNumber is Empty", "error");
            return;
        }
        if (poValidityDate === "" || poValidityDate === null || poValidityDate === undefined) {
            handleSnackOpen("poValidityDate is Empty", "error");
            return;
        }
        if (selectedDate === "" || selectedDate === null || selectedDate === undefined) {
            handleSnackOpen("poDate is Empty", "error");
            return;
        }
        if (poValueVal === "" || poValueVal === null || poValueVal === undefined) {
            handleSnackOpen("poValue is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (noOfBreakersVal === "" || noOfBreakersVal === null || noOfBreakersVal === undefined) {
                handleSnackOpen("NoOfBreaker is Empty", "error");
                return;
            }
        }
        if (soNumberVal === "" || soNumberVal === null || soNumberVal === undefined) {
            handleSnackOpen("SoNumber is Empty", "error");
            return;
        }
        if (assignedResourceVal === "" || assignedResourceVal === null || assignedResourceVal === undefined) {
            handleSnackOpen("AssignedResource is Empty", "error");
            return;
        }
        if (techStatus === "" || techStatus === null || techStatus === undefined) {
            handleSnackOpen("TechStatus is Empty", "error");
            return;
        }

        if (commercialStatus === "" || commercialStatus === null || commercialStatus === undefined) {
            handleSnackOpen("commercialStatus is Empty", "error");
            return;
        }
        if (selectedFile === "" || selectedFile === null || selectedFile === undefined) {
            handleSnackOpen("File is Empty", "error");
            return;
        }
        if (prodFilter === 2) {
            if (breakVal === "" || breakVal === null || breakVal === undefined) {
                handleSnackOpen("breakerQty is Empty", "error");
                return;
            }
            if (resVal === "" || resVal === null || resVal === undefined) {
                handleSnackOpen("reasonForOrderWin is Empty", "error");
                return;
            }
            if (oldVal === "" || oldVal === null || oldVal === undefined) {
                handleSnackOpen("oldMakeBreaker is Empty", "error");
                return;
            }
            if (newVal === "" || newVal === null || newVal === undefined) {
                handleSnackOpen("newBreakerModel is Empty", "error");
                return;
            }

        }
        if (prodFilter === 3) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (meterVal === "" || meterVal === null || meterVal === undefined) {
                handleSnackOpen("metersMake is Empty", "error");
                return;
            }

            if (licenseVal === "" || licenseVal === null || licenseVal === undefined) {
                handleSnackOpen("licenseQty is Empty", "error");
                return;
            }
            if (digiVal === "" || digiVal === null || digiVal === undefined) {
                handleSnackOpen("digiPartner is Empty", "error");
                return;
            }
            if (typeVal === "" || typeVal === null || typeVal === undefined) {
                handleSnackOpen("typeId is Empty", "error");
                return;
            }

        }
        if (prodFilter === 4) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (totalVal === "" || totalVal === null || totalVal === undefined) {
                handleSnackOpen("totalFronts is Empty", "error");
                return;
            }
            if (overallVal === "" || overallVal === null || overallVal === undefined) {
                handleSnackOpen("overallQty is Empty", "error");
                return;
            }



        }
        if (selectedFile == null) {
            formData.append('file', selectedFile);
        }
        else {


            if (selectedFile.length === 0) {
                formData.append('file', selectedFile);
            }
            else {
                for (let i = 0; i < selectedFile.length; i++) {
                    formData.append("Document", selectedFile[i]);

                }
            }
        }
        formData.append('userId', userDetails.userId);
        formData.append('yearId', yearFilter);
        formData.append('productId', prodFilter);
        formData.append('customerId', customer);
        formData.append('codeId', code);
        formData.append('countryId', countryVal);
        formData.append('state', stateVal);
        formData.append('region', regionVal);
        formData.append('office', officeVal);
        formData.append('serviceTypeId', serviceTypeVal);
        formData.append('poNumber', poNumberVal);

        formData.append('poValidity', poValidityDate);
        formData.append('poDated', selectedDate);

        formData.append('poValue', poValueVal);
        formData.append('noOfBreaker', noOfBreakersVal);
        formData.append('sonumber', soNumberVal);
        formData.append('ticketNumber', ticketNumberVal);
        formData.append('assignedResource', assignedResourceVal);
        formData.append('technicalStatusId', techStatus);
        formData.append('commercialStatusId', commercialStatus);

        //Retrofit
        formData.append('breakerQty', breakVal);
        formData.append('reasonForOrderWin', resVal);
        formData.append('oldMakeBreaker', oldVal);
        formData.append('newBreakerModel', newVal);
        formData.append('notificationNumber', notVal);
        formData.append('retrofitPartner', retroVal);
        //ems
        formData.append('orderTypeId', orderValue);
        formData.append('metersMake', meterVal);
        formData.append('licenseQuantity', licenseVal);
        formData.append('digiPartner', digiVal);
        formData.append('typeId', typeVal);

        //panel
        formData.append('totalFronts', totalVal);
        formData.append('overallQtyServices', overallVal);
        console.log("formData", formData);

        setIsLoading(true);
        postPOEntryDetails(formData, siteToken)
            .then((response) => {
                console.log("Resp", response);
                setIsLoading(false);
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    handleCloseForm();
                    // getAllPoEntryDetails();
                    grid(userDetails.userId, prodFilter, yearFilter)
                }
                else {
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseForm();
                }
            })
            .catch((error) => {
                setIsLoading(false);;
                handleSnackOpen(error, "error");
            });

        // const options = {
        //     method: "post",
        //     mode: "cors",
        //     headers: {
        //         Authorization: "Bearer " + siteToken,
        //     },
        //     body: formData,
        // };
        // return fetch(baseApiURL + "/POEntry/PostPOEntryDetails", options)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data.result);
        //     })
        //     .catch((error) => {
        //         console.log("Error : ", error);
        //     });



        // const options = {
        //     method: "post",
        //     mode: "cors",
        //     headers: {
        //         Accept: "application/json, text/plain, */*",
        //         "Content-Type": "application/json",
        //         Authorization: "Bearer " + siteToken,
        //     },
        //     body: formData
        // };

        // fetch(baseApiURL + "/POEntry/PostPOEntryDetails", options)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data);
        //     })
        //     .catch((error) => {
        //         console.log("Error : ", error);
        //     });

        // const options = {
        //     method: "post",
        //     url: "https://localhost:44342/api/POEntry/PostPOEntryDetails",
        //     headers: {
        //         Authorization: "Bearer " + siteToken,
        //     },
        //     body: formData,
        // };
        // api.post(options)
        //     .then(res => {
        //         console.log("ee", res);
        //     })
        //     .catch(error => {
        //         console.log("ff", error);
        //     })

    }
    const [mode, setMode] = useState("");
    const [openForm, setopenForm] = useState(false);
    function handleeditFormClose() {

        setopenForm(false);

    }
    function clickEditProd() {

        if (!prodFilter && !yearFilter) {
            handleSnackOpen("Please select Product Type and Year", "error")
        }
        else if (!prodFilter) {
            handleSnackOpen("Please select Product Type", "error")
        }
        else if (!yearFilter) {
            handleSnackOpen("Please select Year", "error")
        }
        else { window.location.href = "/PrepareOffer"; }

    }
    //function clickEdit() {
    //    console.log("edit data");
    //    //console.log(rowData);
    //    setMode("edit");
    //    setopenForm(true);
    //    //seteditRowData(rowData);

    //    //window.location.href = "/SlipInventory";
    //}
    function handleUpdateForm() {
        console.log("Update Clicked");
        console.log("reason", resVal)
        const formData = new FormData();
        if (customer === "" || customer === null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (code === "" || code === null || code === undefined) {
            handleSnackOpen("code is Empty", "error");
            return;
        }
        if (stateVal === "" || stateVal === null || stateVal === undefined) {
            handleSnackOpen("state is Empty", "error");
            return;
        }
        if (countryVal === "" || countryVal === null || countryVal === undefined) {
            handleSnackOpen("country is Empty", "error");
            return;
        }
        if (regionVal === "" || regionVal === null || regionVal === undefined) {
            handleSnackOpen("region is Empty", "error");
            return;
        }
        if (officeVal === "" || officeVal === null || officeVal === undefined) {
            handleSnackOpen("office is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (serviceTypeVal === "" || serviceTypeVal === null || serviceTypeVal === undefined) {
                handleSnackOpen("serviceType is Empty", "error");
                return;
            }
            if (poNumberVal === "" || poNumberVal === null || poNumberVal === undefined) {
                handleSnackOpen("poNumber is Empty", "error");
                return;
            }
        }
        if (poValidityDate === "" || poValidityDate === null || poValidityDate === undefined) {
            handleSnackOpen("poValidityDate is Empty", "error");
            return;
        }
        if (selectedDate === "" || selectedDate === null || selectedDate === undefined) {
            handleSnackOpen("poDate is Empty", "error");
            return;
        }
        if (poValueVal === "" || poValueVal === null || poValueVal === undefined) {
            handleSnackOpen("poValue is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (noOfBreakersVal === "" || noOfBreakersVal === null || noOfBreakersVal === undefined) {
                handleSnackOpen("NoOfBreaker is Empty", "error");
                return;
            }
        }
        if (soNumberVal === "" || soNumberVal === null || soNumberVal === undefined) {
            handleSnackOpen("SoNumber is Empty", "error");
            return;
        }
        if (assignedResourceVal === "" || assignedResourceVal === null || assignedResourceVal === undefined) {
            handleSnackOpen("AssignedResource is Empty", "error");
            return;
        }
        if (techStatus === "" || techStatus === null || techStatus === undefined) {
            handleSnackOpen("TechStatus is Empty", "error");
            return;
        }

        if (commercialStatus === "" || commercialStatus === null || commercialStatus === undefined) {
            handleSnackOpen("commercialStatus is Empty", "error");
            return;
        }
        //if (selectedFile === "" || selectedFile === null || selectedFile === undefined) {
        //    handleSnackOpen("File is Empty", "error");
        //    return;
        //}
        if (prodFilter === 2) {
            if (breakVal === "" || breakVal === null || breakVal === undefined) {
                handleSnackOpen("breakerQty is Empty", "error");
                return;
            }
            if (resVal === "" || resVal === null || resVal === undefined) {
                handleSnackOpen("reasonForOrderWin is Empty", "error");
                return;
            }
            if (oldVal === "" || oldVal === null || oldVal === undefined) {
                handleSnackOpen("oldMakeBreaker is Empty", "error");
                return;
            }
            if (newVal === "" || newVal === null || newVal === undefined) {
                handleSnackOpen("newBreakerModel is Empty", "error");
                return;
            }

        }
        if (prodFilter === 3) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (meterVal === "" || meterVal === null || meterVal === undefined) {
                handleSnackOpen("metersMake is Empty", "error");
                return;
            }

            if (licenseVal === "" || licenseVal === null || licenseVal === undefined) {
                handleSnackOpen("licenseQty is Empty", "error");
                return;
            }
            if (digiVal === "" || digiVal === null || digiVal === undefined) {
                handleSnackOpen("digiPartner is Empty", "error");
                return;
            }
            if (typeVal === "" || typeVal === null || typeVal === undefined) {
                handleSnackOpen("typeId is Empty", "error");
                return;
            }

        }
        if (prodFilter === 4) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (totalVal === "" || totalVal === null || totalVal === undefined) {
                handleSnackOpen("totalFronts is Empty", "error");
                return;
            }
            if (overallVal === "" || overallVal === null || overallVal === undefined) {
                handleSnackOpen("overallQty is Empty", "error");
                return;
            }



        }
        if (selectedFile == null) {
            formData.append('file', selectedFile);
        }
        else {


            if (selectedFile.length === 0) {
                formData.append('file', selectedFile);
            }
            else {
                for (let i = 0; i < selectedFile.length; i++) {
                    formData.append("Document", selectedFile[i]);

                }
            }
        }

        formData.append('userId', userDetails.userId);
        formData.append('yearId', yearFilter);
        formData.append('productId', prodFilter);
        formData.append('customerId', customer);
        formData.append('codeId', code);
        formData.append('countryId', countryVal);
        formData.append('state', stateVal);
        formData.append('region', regionVal);
        formData.append('office', officeVal);
        formData.append('serviceTypeId', serviceTypeVal);
        formData.append('poNumber', poNumberVal);

        formData.append('poValidity', poValidityDate);
        formData.append('poDated', selectedDate);

        formData.append('poValue', poValueVal);
        formData.append('noOfBreaker', noOfBreakersVal);
        formData.append('sonumber', soNumberVal);
        formData.append('ticketNumber', ticketNumberVal);
        formData.append('assignedResource', assignedResourceVal);
        formData.append('technicalStatusId', techStatus);
        formData.append('commercialStatusId', commercialStatus);
        formData.append('EpPoentryPageId', epPoentryId);
        //Retrofit
        formData.append('breakerQty', breakVal);
        formData.append('reasonForOrderWin', resVal);
        formData.append('oldMakeBreaker', oldVal);
        formData.append('newBreakerModel', newVal);
        formData.append('notificationNumber', notVal);
        formData.append('retrofitPartner', retroVal);
        //ems
        formData.append('orderTypeId', orderValue);
        formData.append('metersMake', meterVal);
        formData.append('licenseQuantity', licenseVal);
        formData.append('digiPartner', digiVal);
        formData.append('typeId', typeVal);
        //panel
        formData.append('totalFronts', totalVal);
        formData.append('overallQtyServices', overallVal);

        console.log("formData", formData);

        setIsLoading(true);
        updatePOEntryDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("Resp", response);
                setIsLoading(false);
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    handleCloseForm();
                    //getAllPoEntryDetails();
                    grid(userDetails.userId, prodFilter, yearFilter)

                }
                else {
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseForm();
                }
            })
            .catch((error) => {
                setIsLoading(false);;
                handleSnackOpen(error, "error");
            });


    }
    function convertDateFormat(selData) {
        var dateStr =
            selData.getFullYear() + "-" +
            ("00" + (selData.getMonth() + 1)).slice(-2) + "-" +
            ("00" + selData.getDate()).slice(-2) + " " +
            ("00" + selData.getHours()).slice(-2) + ":" +
            ("00" + selData.getMinutes()).slice(-2) + ":" +
            ("00" + selData.getSeconds()).slice(-2);

        return dateStr;
    }
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        //const selData = convertDateFormat(date);
        //console.log(selData);
        date.toISOString();

        setSelectedDate(date);
    };
    const [est, setEst] = useState(null);
    const handleDateChangeEst = (date) => {
        // const selData = convertDateFormat(date);
        // console.log(selData);
        date.toISOString();

        setEst(date);
    };

    const [poValidityDate, setPOValidityDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const handlePOValDateChange = (date) => {
        const selData = convertDateFormat(date);
        const d2 = convertDateFormat(d1);
        console.log(selData);
        setPOValidityDate(selData);
        setMaxDate(d2);
        console.log("dated", maxDate)
    };
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    function handleInputChange(event, value) {
        console.log("value", value);
        setSearchValue(value);
        setCustomerText(value);
        console.log("searchval", searchValue)
        //console.log("search", value);
        if (value.length >= 3) {
            //const token = localStorage.getItem("access_token");
            getCustDetailsMaster(value);
        }

    }
    function getCustomTBodyForTotal(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_ACB, 0)
                            )}

                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.nOofBreaker_ACB, 0)
                            )}

                        </TableCell>



                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function getCustomTBodyForTotalRetrofit(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_Retrofit, 0)
                            )}

                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.breakerQty_Retrofit, 0)
                            )}

                        </TableCell>



                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function getCustomTBodyForTotalEms(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={9}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_EMS, 0)
                            )}

                        </TableCell>




                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function getCustomTBodyForTotalPanel(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_Panel, 0)
                            )}

                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalFronts_Panel, 0)
                            )}

                        </TableCell>




                    </TableRow>
                ))}
            </TableBody>
        );
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="text-left ml-1">
                    {/*<h2>Offer</h2>*/}
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            FY Filter
                        </InputLabel>
                        <Select
                            label="Year Filter"
                            id=""
                            name=""
                            className=""
                            fullWidth
                            value={yearFilter}
                            onChange={yearFilterChange}
                            input={<BootstrapInput />}
                        >
                            {uniqueSerialMaster.map((item) => (
                                <MenuItem key={item.yearId} value={item.yearId}>
                                    {item.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Product
                        </InputLabel>
                        <Select
                            label="Product Filter"
                            id=""
                            name=""
                            className=""
                            fullWidth
                            value={prodFilter}
                            onChange={prodFilterChange}
                            input={<BootstrapInput />}
                        >
                            {prodMasterData.map((item) => (
                                <MenuItem key={item.offerId} value={item.offerId}>
                                    {item.offerName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={3} className="mr-btm">
                    <Button className="pt-button--primary button-submit"
                        variant="contained"
                        onClick={getOffTable}
                    >
                        Search
                    </Button>
                </Grid>
                <Grid xs={12} sm={3} className="mr-btm">
                    <Button className="pt-button--primary button-submit"
                        variant="contained"
                        onClick={clickEditProd}
                    //disabled={ !prodFilter}
                    >
                        Prepare Offer
                    </Button>
                </Grid>


                <Grid xs={12}>
                    <MaterialTable
                        title="Offer List"
                        columns={col}
                        data={data}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}

                        options={{
                            /*rowStyle: rowData => new Date(rowData.povalidity).getTime() > new Date(d1).getTime() ? ({ backgroundColor: "rgba(255,84,84,0.5019607843137255)" }) : ({ backgroundColor: "#FFFFFF", }),*/
                            exportButton: { csv: true }, exportAllData: true,
                            //exportCsv: (columns, data) => {
                            //    console.log("Report Icon Clicked");
                            //    //getQuotationListReport();
                            //},
                            headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "370px", searchFieldAlignment: "left",
                            toolbarButtonAlignment: "left",
                        }}
                        actions={[
                            //{
                            //    icon: GetAddButton,
                            //    isFreeAction: true,
                            //    //onClick: () => {
                            //    //    setDialogSubmitBtn("Save");
                            //    //    setCustDialogTitle("Add PO Entry");
                            //    //    setOpenDialog(true);
                            //    //},
                            //},
                            {
                                icon: GetEditButton,
                                tisFreeAction: true,
                                //onClick: (e, rowData) => {

                                //    //setDialogSubmitBtn("Update");
                                //    //setCustDialogTitle("Update Offer");
                                //    //object(rowData.epPoentryPageId);
                                //    //getCustDetailsMaster(rowData.customerName)
                                //    //handleInputChange(e, rowData.customerName)
                                //    //getCustDetailsMaster(rowData.customerName)
                                //    //dialogDraftValuesFn(rowData);
                                //},
                                onClick: (event, rowData) => clickEdit(rowData)
                            },
                            {
                                icon: GetDeleteButton,
                                tisFreeAction: false,
                                onClick: (e, rowData) => {
                                    console.log("Row Data", rowData);
                                    //deletePoEntryRow(rowData);
                                    handleDeleteIconClick(rowData);
                                },


                            },



                        ]}
                    //components={{
                    //    Header: (props) => (
                    //        <>

                    //            <MTableHeader {...props} />
                    //            {getCustomTBodyForTotal(totals)}
                    //        </>
                    //    ),
                    //    Body: (props) => (
                    //        <>
                    //            <MTableBody {...props} />
                    //        </>
                    //    ),

                    //}}
                    />
                </Grid>


                <Dialog
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseForm();
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openDialog}
                    maxWidth={"md"}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="dialogTitleTxt">{custDialogTitle}</DialogTitle>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleCloseForm}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6} md={4} >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="MM/dd/yyyy"
                                            placeholder="MM/dd/yyyy"
                                            fullWidth
                                            // maxDate={new Date()}
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // margin="normal"
                                            id="date-picker-dialog"
                                            label="Estimate"
                                            format="MM/dd/yyyy"
                                            placeholder="MM/dd/yyyy"
                                            fullWidth
                                            // maxDate={new Date()}
                                            value={est}
                                            onChange={handleDateChangeEst}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Reference"
                                        variant="filled"
                                        value={refe}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeRef}
                                        id="poNumber"
                                    />
                                </Grid>
                                {/*<Grid item xs={12} sm={6} md={4}>*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="poNumber"*/}
                                {/*        label="Customer Name"*/}
                                {/*        variant="filled"*/}
                                {/*        value={cust}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        onChange={handleChangeCust}*/}
                                {/*        id="poNumber"*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">
                                    <Autocomplete
                                        //freeSolo={options && options.length > 0 ? true : false}
                                        className="lightInputFields hover-lightblue"
                                        id="global-search"
                                        fullWidth
                                        //value={searchValue }
                                        //style={{ width: 200 }}
                                        disableClearable
                                        value={customerObj}

                                        inputValue={customerText}
                                        options={itemsCustomer}
                                        getOptionLabel={(option) => (option.customerName1 ? option.customerName1 : "")}
                                        //forcePopupIcon={false}
                                        // noOptionsText={searchValue && searchValue.length > 0 ? "No Results Found" : "Type to search"}
                                        onChange={handleChangeCustomer}
                                        onInputChange={handleInputChange}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        // filterOptions={(options,state)=>options }
                                        filteselectedoptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Customer"
                                                // placeholder="Search"
                                                value={searchValue}
                                                style={{ backgroundColor: "var(--light-sand)" }}
                                                variant="filled"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    type: "search",
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Status"
                                        variant="filled"
                                        value={status}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeStatus}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="State"
                                        variant="filled"
                                        value={stat}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeStat}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Amount"
                                        variant="filled"
                                        value={amt}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Discount(%)"
                                        variant="filled"
                                        value={disc}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Discount(Amt)"
                                        variant="filled"
                                        value={discA}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="CGST"
                                        variant="filled"
                                        value={cgst}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="SGST"
                                        variant="filled"
                                        value={sgst}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="IGST"
                                        variant="filled"
                                        value={igst}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid><Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Adjustments"
                                        variant="filled"
                                        value={adj}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Other Charges"
                                        variant="filled"
                                        value={others}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Total"
                                        variant="filled"
                                        value={tottal}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeAmt}
                                        id="poNumber"
                                    />
                                </Grid>

                            </Grid>



                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            className="pt-button--secondary"
                            id="addUserSave"
                            onClick={
                                dialogSubmitBtn === "Save" ? handleSaveForm : handleUpdate
                            }
                        >
                            {dialogSubmitBtn}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="dialogTitleTxt">Delete Offer</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to Delete this Offer ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={Del}
                            className="pt-button--primary"
                        >
                            Ok
                        </Button>
                        <Button
                            onClick={handleCloseDeleteDialog}
                            className="pt-button--secondary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </Grid>

            <Dialog
                open={openForm}
                disableBackdropClick
                maxWidth={"lg"}
                aria-labelledby="edit-dialog-title"
                onClose={handleeditFormClose}
            >
                <DialogContent>
                    <PrepareOffer clickgoback={goBackonClick} editData={editRowData} Mode={mode} ></PrepareOffer>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDropdown}
                onClose={handleCloseDropdown}
                fullWidth
                maxWidth={"md"}
                // maxWidth={"lg"} 
                aria-labelledby="edit-dialog-title"

            >
                <DialogTitle id="dialogTitleTxt">Signatory Approval</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={8} md={6} >
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        Technical Approval
                                    </InputLabel>

                                    <Select

                                        label="Name"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        disabled={techflag}
                                        value={totalValue}
                                        onChange={handleChangeTotalValue}
                                        input={<BootstrapInput />}
                                    >
                                        {totalValues.map((item) => (
                                            <MenuItem key={item.userId} value={item.userId}>
                                                {item.userEmail}
                                            </MenuItem>
                                        ))}


                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} >
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        Commercial Approval
                                    </InputLabel>

                                    <Select

                                        label="Name"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={approve}
                                        onChange={handleChangeApprove}
                                        input={<BootstrapInput />}
                                    >
                                        {approves.map((item) => (
                                            <MenuItem key={item.userId} value={item.userId}>
                                                {item.userEmail}
                                            </MenuItem>
                                        ))}


                                    </Select>
                                </FormControl>
                            </Grid>









                        </Grid>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleApprove}
                        className="pt-button--primary"
                    >
                        Update
                    </Button>
                    <Button
                        onClick={handleCloseDropdown}
                        className="pt-button--primary"
                        autoFocus
                    >
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog fullWidth
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseDia();
                    }
                }}
                aria-labelledby="litigation-form--registration"
                open={openDia}
                maxWidth={"lg"}
                disableEscapeKeyDown
            >
                <DialogTitle id="responsive-dialog-title">Upload Offer</DialogTitle>
                <div style={{ position: "absolute", right: "1%" }}>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseDia}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container spacing={2}>
                            <Grid item xs={12} className="text-left ml-1">
                                <h2>Upload Offer</h2>
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        id="EPQUploadFile"
                                        variant="filled"
                                        className="bulk-upload"
                                        type="file"
                                        name="bulkUpload"
                                        key={inputFileValue}
                                        onChange={updateExcelHandler}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        className="pt-button--primary bulk-upload-submit-btn"
                                        type="submit"
                                        // disabled={selectedFile ? false : true}
                                        onClick={submitValueDetails}
                                    >
                                        Upload
                                    </Button>
                                </Grid>



                            </Grid>


                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/*<Button*/}
                    {/*    type="submit"*/}
                    {/*    fullWidth*/}
                    {/*    className="pt-button--secondary"*/}
                    {/*    id="addUserSave"*/}
                    {/*    onClick={*/}
                    {/*        formDialogTitle === "Save" ? handleSaveForm : handleUpdateForm*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    {formDialogTitle}*/}
                    {/*</Button>*/}
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {" Data Upload Alert"}
                </DialogTitle>
                <DialogContent>
                    {dialogContent && typeof dialogContent === "object" ? (
                        <DialogContentText id="alert-dialog-description">
                            <h5>
                                {dialogContent
                                    ?.filter((x, index) => index === 0)
                                    ?.map((c) => (
                                        <>&emsp;&emsp;{c.join(" ")}</>
                                    ))}
                            </h5>
                            <ol className="ol-bulkUpload-Error">
                                {dialogContent
                                    ?.filter((x, index) => index !== 0)
                                    ?.map((c) => (
                                        <li>
                                            {c.map((x) =>
                                                x.includes("--") && x.includes(":") ? (
                                                    x
                                                ) : (
                                                    <ul className="ul-bulkUpload-Error">
                                                        <li>{x}</li>
                                                    </ul>
                                                )
                                            )}
                                        </li>
                                    ))}
                            </ol>
                        </DialogContentText>
                    ) : (
                        <DialogContentText
                            id="alert-dialog-description "
                            className="dialog-center"
                        >
                            {dialogContent?.includes("successfully.") ? (
                                <>
                                    {dialogContent?.split("successfully.")[0] +
                                        " successfully."}
                                    {dialogContent?.split("successfully.")?.length > 0 ? (
                                        <>
                                            <br />
                                            {dialogContent?.split("successfully.")[1]}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                dialogContent
                            )}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className="pt-button--secondary"
                        title="Close"
                    >
                        Close
                    </Button>
                    {errorData && errorData.length > 0 ? (
                        <Button
                            //onClick={exportErrorData}
                            className="pt-button--secondary"
                            title={
                                "Export   upload error data to excel"
                            }
                        >
                            Export Error Data
                        </Button>
                    ) : (
                        <></>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                open={openEmailDialog}
                onClose={handleCloseEmailDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogContent>
                    <Grid container spacing={2} className="">
                        <Grid item xs={12} md={6} className="d-flex jc-flex-start">
                            <h4>Email Details:-</h4>
                        </Grid>
                        <Grid item xs={12} md={6} className="d-flex jc-flex-end">
                            <p style={{ color: 'red' }}>Note:-All fields are mandatory</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                className=""
                                fullWidth
                                name="to"
                                label="TO"
                                variant="filled"
                                value={to}
                                helperText={toHelper}
                                error={toHelper ? true : false}
                                onChange={handleChangeTo}
                                id="to"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                className=""
                                fullWidth
                                name="cc"
                                label="CC"
                                variant="filled"
                                value={cc}
                                helperText={ccHelper}
                                error={ccHelper ? true : false}
                                onChange={handleChangeCC}
                                id="cc"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className=""
                                fullWidth
                                name="sub"
                                label="Subject"
                                variant="filled"
                                value={subject}
                                helperText={subjectHelper}
                                error={subjectHelper ? true : false}
                                onChange={handleChangeSubject}
                                id="sub"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextareaAutosize
                                minRows={4} fullWidth
                                aria-label="Body"
                                placeholder="Body"
                                defaultValue={body}
                                onChange={handleChangeBody}
                                style={{ width: '100%', border: '0', borderBottom: '1px solid #00CCCC', backgroundColor: '#EBF7F8' }}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEmailDialog} className="pt-button--secondary">
                        Close
                    </Button>
                    <Button onClick={Sendmail} className="pt-button--primary button-submit" disabled={!to || !cc || !subject || !body}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
            {isLoader ? <Loader loaderText="Loading...!" /> : <></>}

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );

}



